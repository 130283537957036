@import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap');

.App{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction:column;
  align-items: center;
  background-color:#2f74c0;
  font-family: 'Neucha', cursive;


  background: rgba( 255, 0, 4, 9 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 9px );

}
.heading{
  font-size:40px;
  margin: 30px 0;
  z-index:1;
  text-align: center;
  color:#fff; 
}
@media(max-width:880px){
  .heading{
    font-size:35px;
    margin: 15px 0;

  }
}
